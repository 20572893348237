const getApiHost = () => {
  const { hostname } = window.location;
  if (hostname === 'localhost') {
    return 'http://localhost:8000';
  } else {
    return 'https://auto-fingering-api-a4b6efhfeafhbvg2.canadacentral-01.azurewebsites.net';
  }
};

export { getApiHost };
