import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Footer } from '../components/Footer';
import { Link } from 'react-router-dom';
import { OpenSheetMusicDisplay } from 'opensheetmusicdisplay';
import { useFileUpload } from '../hooks/useFileUpload';
import { updateEntryFingerColor } from '../utils/updateEntryFingerColor';
import { getFingeringLabel } from '../utils/getFingeringLabel';
import { swapFingeringData } from '../utils/swapFingeringData';
import { updateFingeringLabelContent } from '../utils/updateFingeringLabelContent';
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
} from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { useSubscriptionStatus } from '../hooks/useSubscriptionStatus';
import {
  getDraggedFingering,
  createLetterElement,
  createLineElements,
  removeElementsByClassName,
} from '../utils/mainFingerEditUtils';
import { socialLinks } from 'src/socialLinks';
import { useErrorBoundary } from 'react-error-boundary';

const AutoFingering = () => {
  const isAuthenticated = useIsAuthenticated();
  const { subscriptionStatus } = useSubscriptionStatus(isAuthenticated);

  const osmdContainerRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [osmd, setOsmd] = useState<OpenSheetMusicDisplay | null>(null);
  const { showBoundary } = useErrorBoundary();

  const { fileXML, fingering, status, handleFileChange, uploadFile, fileName } =
    useFileUpload(osmd, setOsmd);

  // Set the title of the page
  useEffect(() => {
    document.title = 'Auto Fingering - PineappleMusicLab';
  }, []);

  /**
   * Validate the fingering data against the generated osmd xml object
   */
  const checkNumEntries = useCallback(() => {
    console.log('checkNumEntries');
    if (!osmd) {
      console.error('OSMD graphic not properly initialized.');
      return;
    }
    fingering.forEach((measure, measureIndex) => {
      measure.forEach((staff, staffIndex) => {
        // check if the number of entries from the current osmd staff
        // matches the number of entries from the current osmd fingering data staff
        const numEntriesOsmd =
          osmd.GraphicSheet.MeasureList[measureIndex][staffIndex].staffEntries
            .length;
        const numEntriesFingering = staff.length;
        if (numEntriesOsmd !== numEntriesFingering) {
          console.warn('Number of entries mismatch:', {
            measureIndex: measureIndex,
            staffIndex: staffIndex,
            numEntriesOsmd: numEntriesOsmd,
            numEntriesFingering: numEntriesFingering,
          });
        }
      });
    });
    //TODO: more validations to come
  }, [osmd, fingering]);

  /**
   * Function to change the color of all fingering labels in the OSMD display according to given parameters
   * @param hideUnimportant - whether to set unimportant fingerings white
   * @param hideSameAlternate - whether to set alternate fingering of the entry white if same as main fingerings
   * @param hideAllAlternate - if to hide all alternate finger
   */
  const changeFingerColor = useCallback(
    (hideUnimportant = true, hideAllAlternate = false) => {
      console.log('changeFingerColor', {
        hideUnimportant,
        hideAllAlternate,
      });
      if (!osmd) {
        console.error('OSMD graphic not properly initialized.');
        return;
      }
      if (fingering.length == 0) {
        console.log('No fingering present');
        return;
      }
      fingering.forEach((measure, measureIndex) => {
        measure.forEach((staff, staffIndex) => {
          staff.forEach((entryData, entryIndex) => {
            const osmdStaffEntry =
              osmd.GraphicSheet.MeasureList[measureIndex][staffIndex]
                .staffEntries[entryIndex];

            // get the color for alternative fingerings
            const altColor = hideAllAlternate ? '#FFFFFF' : '#C0C0C0';

            // set color for alternate fingerings
            if (entryData.altFinger) {
              updateEntryFingerColor(
                osmdStaffEntry,
                staffIndex,
                altColor,
                entryData
              );
            }

            // Hide unimportant main fingerings by setting them to white
            if (!entryData.mainCritical && hideUnimportant) {
              updateEntryFingerColor(
                osmdStaffEntry,
                staffIndex,
                '#FFFFFF',
                entryData
              );
            }
          });
        });
      });
    },
    [fingering, osmd]
  );

  /**
   * Swap the main and alternative fingerings displayed in osmd, and propagate to the adjacent entries that have
   * different main and alternative finger
   * @param measureIndex
   * @param staffIndex
   * @param entryIndex
   */
  const swapFingerOnScore = useCallback(
    (
      // applyUpdatedFingerings / render...
      measureIndex: number,
      staffIndex: number,
      entryIndex: number
    ) => {
      console.log('swapFingerOnScore');
      if (!osmd) {
        console.error('OSMD graphic not properly initialized.');
        return;
      }
      //TODO: instead checking for the staff, we can simply swap the first half and the second half
      const entry = fingering[measureIndex][staffIndex][entryIndex];
      const mainFingerList = entry.mainFinger;
      const altFingerList = entry.altFinger ?? [];
      const osmdStaffEntry =
        osmd.GraphicSheet.MeasureList[measureIndex][staffIndex].staffEntries[
          entryIndex
        ];

      mainFingerList.forEach((finger, i) => {
        let labelIndex = i;
        if (staffIndex === 0) {
          labelIndex = i + mainFingerList.length;
        }
        const mainLabel = getFingeringLabel(osmdStaffEntry, labelIndex);
        if (!mainLabel) {
          console.log('No label found', {
            measureIndex,
            staffIndex,
            entryIndex,
            labelIndex: i,
          });
          return;
        }
        updateFingeringLabelContent(mainLabel, finger.toString());
      });

      altFingerList.forEach((finger, i) => {
        let labelIndex = i;
        if (staffIndex === 1) {
          labelIndex = i + mainFingerList.length;
        }
        const altLabel = getFingeringLabel(osmdStaffEntry, labelIndex);
        if (!altLabel) {
          console.log('No label found', {
            measureIndex,
            staffIndex,
            entryIndex,
            labelIndex: i + mainFingerList.length,
          });
          return;
        }
        updateFingeringLabelContent(altLabel, finger.toString());
      });
    },
    [fingering, osmd]
  );

  /**
   * Handle the logic for clicking on a label to swap the color and position of main fingerings and alternative finger
   * @param measureIndex
   * @param staffIndex
   * @param entryIndex
   */
  const swapAltLabels = useCallback(
    (
      measureIndex: number,
      staffIndex: number,
      entryIndex: number,
      group: boolean
    ) => {
      console.log('handleAlternativeLabelClick');
      const currentEntry = fingering[measureIndex][staffIndex][entryIndex];

      if (!currentEntry.altFinger || !currentEntry.flipGroup) return; //maybe we should do something but meh

      if (group) {
        // swap finger for all notes in the flip group
        currentEntry.flipGroup.forEach((group) => {
          swapFingeringData(
            fingering[group.measureIndex][staffIndex][group.indexInMeas]
          );
          swapFingerOnScore(group.measureIndex, staffIndex, group.indexInMeas);
        });
      } else {
        // swap finger for the current note
        swapFingeringData(fingering[measureIndex][staffIndex][entryIndex]);
        swapFingerOnScore(measureIndex, staffIndex, entryIndex);
      }
    },
    [fingering, swapFingerOnScore]
  );

  /**
   * Update the given fingering label
   * @param label the exact SVGElement label inside the staff entry
   * @param newFinger
   */
  const updateFingeringLabel = useCallback(
    (label: SVGElement, newFingerStr: string) => {
      if (!osmd) {
        console.error('OSMD graphic not properly initialized.');
        return;
      }

      updateFingeringLabelContent(label, newFingerStr);
    },
    [osmd]
  );

  /**
   * Add onclick handlers to all the fingering labels
   */
  const addSwapEditHandlers = useCallback(() => {
    console.log('addSwapAltHandlers');
    if (!osmd) {
      console.error('OSMD graphic not properly initialized.');
      return;
    }

    if (fingering.length === 0) {
      console.log('No fingering present');
      return;
    }
    fingering.forEach((measure, measureIndex) => {
      measure.forEach((staff, staffIndex) => {
        staff.forEach((entry, entryIndex) => {
          const osmdStaffEntry =
            osmd.GraphicSheet.MeasureList[measureIndex][staffIndex]
              .staffEntries[entryIndex];

          if (entry.mainFinger && entry.mainFinger.length > 0) {
            let idxStart = 0;
            let idxEnd = entry.mainFinger.length;
            if (entry.altFinger && entry.altFinger.length > 0) {
              idxStart = staffIndex === 1 ? 0 : entry.mainFinger.length;
              idxEnd =
                staffIndex === 1
                  ? entry.mainFinger.length
                  : entry.mainFinger.length * 2;
            }

            for (let i = idxStart; i < idxEnd; i++) {
              const labelIndex = i;
              const label = getFingeringLabel(osmdStaffEntry, labelIndex);
              if (!label) {
                console.log('No label found', {
                  measureIndex,
                  staffIndex,
                  entryIndex,
                  labelIndex,
                });
                return;
              }
              // Add mouse handlers for drag main finger
              label.addEventListener('mousedown', (event) => {
                event.stopPropagation();
                event.preventDefault();
                handleDragMouseDown(event, label);
              });
              // Add touch handlers for drag main finger
              label.addEventListener('touchstart', (event) => {
                event.stopPropagation();
                event.preventDefault();
                handleDragTouchStart(event, label);
              });
            }
            // Add handlers for alternative finger
            if (entry.altFinger && entry.altFinger.length > 0) {
              const idxStart = staffIndex === 1 ? entry.mainFinger.length : 0;
              const idxEnd =
                staffIndex === 1
                  ? entry.mainFinger.length * 2
                  : entry.mainFinger.length;

              for (let i = idxStart; i < idxEnd; i++) {
                const labelIndex = i;
                const label = getFingeringLabel(osmdStaffEntry, labelIndex);

                if (!label) {
                  console.log('No label found', {
                    measureIndex,
                    staffIndex,
                    entryIndex,
                    labelIndex,
                  });
                  return;
                }
                // Add mouse handlers for alternative finger
                label.addEventListener('mousedown', (event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleSwapMouseDown(
                    event,
                    measureIndex,
                    staffIndex,
                    entryIndex
                  );
                });
                // Add touch handlers for alternative finger
                label.addEventListener('touchstart', (event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleSwapTouchStart(
                    event,
                    measureIndex,
                    staffIndex,
                    entryIndex
                  );
                });
              }
            }
          }
        });
      });
    });
  }, [fingering, swapAltLabels, updateFingeringLabel, osmd]);

  const handleDragStart = (event: Touch | MouseEvent) => {
    const xStart = event.pageX;
    const yStart = event.pageY;

    const target = event.target as HTMLElement;
    const originalFingering = target.textContent || '';

    const letterElement = createLetterElement(event, originalFingering);
    const numList = originalFingering.split('-').map(Number);
    const numLength = numList.length;
    createLineElements(event, numLength);
    return { xStart, yStart, letterElement, originalFingering };
  };

  const handleDragMove = (
    event: Touch | MouseEvent,
    xStart: number,
    yStart: number,
    letterElement: HTMLElement,
    originalFingering: string
  ) => {
    letterElement.style.top = `${event.pageY - 50}px`;
    letterElement.style.left = `${event.pageX - 50}px`;
    letterElement.innerText = getDraggedFingering(
      event.pageX - xStart,
      event.pageY - yStart,
      originalFingering
    );
  };

  const handleDragEnd = (label: SVGElement, letterElement: HTMLElement) => {
    updateFingeringLabel(label, letterElement.innerText);
    letterElement.remove();
    removeElementsByClassName('temp-line');
  };

  /**
   * Handles mouse down events for dragging fingering labels.
   */
  const handleDragMouseDown = (event: MouseEvent, label: SVGElement) => {
    if (event.buttons !== 1) return; // Ignore right/middle clicks

    const { xStart, yStart, letterElement, originalFingering } =
      handleDragStart(event);

    const handleDragMouseMove = (e: MouseEvent) => {
      handleDragMove(e, xStart, yStart, letterElement, originalFingering);
    };

    const handleDragMouseUp = () => {
      handleDragEnd(label, letterElement);
      document.removeEventListener('mousemove', handleDragMouseMove);
      document.removeEventListener('mouseup', handleDragMouseUp);
    };

    document.addEventListener('mousemove', handleDragMouseMove);
    document.addEventListener('mouseup', handleDragMouseUp);
  };

  /**
   * Handles touch start events for dragging fingering labels on touch devices.
   */
  const handleDragTouchStart = (event: TouchEvent, label: SVGElement) => {
    if (event.touches.length > 1) return; // Ignore additional touches
    const touch = event.changedTouches[0];
    const { xStart, yStart, letterElement, originalFingering } =
      handleDragStart(touch);

    const handleDragTouchMove = (e: TouchEvent) => {
      if (e.touches.length > 1) return; // Ignore additional touches
      const touch = e.changedTouches[0];
      handleDragMove(touch, xStart, yStart, letterElement, originalFingering);
    };

    const handleDragTouchEnd = () => {
      handleDragEnd(label, letterElement);
      document.removeEventListener('touchmove', handleDragTouchMove);
      document.removeEventListener('touchend', handleDragTouchEnd);
    };

    document.addEventListener('touchmove', handleDragTouchMove, {
      passive: false,
    });
    document.addEventListener('touchend', handleDragTouchEnd);
  };

  /**
   * Handles mouse down events for alternative labels with long press detection.
   */
  const handleSwapMouseDown = (
    event: MouseEvent,
    measureIndex: number,
    staffIndex: number,
    entryIndex: number
  ) => {
    if (event.buttons !== 1) return; // Ignore right/middle clicks

    let isLongPress = false;
    const holdTimer = setTimeout(() => {
      isLongPress = true;
      swapAltLabels(measureIndex, staffIndex, entryIndex, true);
    }, 250);

    /** Cancels the hold timer if the mouse is released before the threshold. */
    const cancelHold = () => {
      clearTimeout(holdTimer);
      if (!isLongPress) {
        swapAltLabels(measureIndex, staffIndex, entryIndex, false);
      }
      document.removeEventListener('mouseup', cancelHold);
      document.removeEventListener('mouseleave', cancelHold);
    };

    document.addEventListener('mouseup', cancelHold);
    document.addEventListener('mouseleave', cancelHold);
  };

  /**
   * Handles touch start events for alternative labels with long press detection.
   */
  const handleSwapTouchStart = (
    event: TouchEvent,
    measureIndex: number,
    staffIndex: number,
    entryIndex: number
  ) => {
    if (event.touches.length > 1) return; // Ignore additional touches

    let isLongPress = false;
    const holdTimer = setTimeout(() => {
      isLongPress = true;
      swapAltLabels(measureIndex, staffIndex, entryIndex, true);
    }, 250);

    /** Cancels the hold timer if the touch is released before the threshold. */
    const cancelHold = () => {
      clearTimeout(holdTimer);
      if (!isLongPress) {
        swapAltLabels(measureIndex, staffIndex, entryIndex, false);
      }
      document.removeEventListener('touchend', cancelHold);
      document.removeEventListener('touchcancel', cancelHold);
    };

    document.addEventListener('touchend', cancelHold);
    document.addEventListener('touchcancel', cancelHold);
  };

  // Function to add show/hide handler for alternate fingerings
  const addShowHideHandler = useCallback(
    (buttonRef: React.RefObject<HTMLButtonElement | null>) => {
      console.log('addShowHideHandler');
      if (!buttonRef.current) {
        console.warn('Button ref not found');
        return;
      }

      let showAltFinger = true;
      buttonRef.current.addEventListener('click', () => {
        showAltFinger = !showAltFinger;
        changeFingerColor(false, !showAltFinger);
      });
    },
    [changeFingerColor]
  );

  useEffect(() => {
    const loadOsmd = async () => {
      if (!osmdContainerRef.current) return;
      osmdContainerRef.current.innerHTML = '';
      const newOsmd = new OpenSheetMusicDisplay(osmdContainerRef.current, {
        autoResize: false,
        autoBeam: true,
        drawComposer: false,
        drawMeasureNumbersOnlyAtSystemStart: true,
        newSystemFromXML: true,
        newSystemFromNewPageInXML: true,
        newPageFromXML: true,
        stretchLastSystemLine: true,
        backend: 'svg',
        pageBackgroundColor: '#FFFFFF',
      });

      await newOsmd.load(fileXML);
      newOsmd.render();
      setOsmd(newOsmd);
      console.info('osmd loaded');
    };
    if (fileXML && fingering) {
      try {
        loadOsmd();
        console.log('fingering loaded:', fingering);
      } catch (error) {
        console.warn('Error loading OSMD', error);
        showBoundary(error);
      }
    }
  }, [fileXML, fingering, showBoundary]);

  useEffect(() => {
    // check if osmd is null
    if (osmd) {
      // validate
      checkNumEntries();
      // set the default colors for alternative finger
      changeFingerColor(false, false);
      // add onclick handlers to all the fingering labels
      addSwapEditHandlers();
      // add show/hide handler for alternate
      addShowHideHandler(buttonRef);
    } else {
      console.warn('OSMD not loaded');
    }
  }, [
    addShowHideHandler,
    addSwapEditHandlers,
    changeFingerColor,
    checkNumEntries,
    osmd,
  ]);

  return (
    <>
      <div className="my-6 flex flex-col items-center font-[Outfit]">
        <div className="flex w-3/4 flex-col items-center gap-y-8 text-gray-600">
          {/* Title Section */}
          <div className="text-4xl tracking-tight">
            <p>Piano Fingering Generator</p>
          </div>

          {/* File Upload Section */}
          <div className="flex flex-col items-center gap-y-3">
            <label
              htmlFor="file-upload"
              className={`
                w-3xs cursor-pointer rounded bg-gray-600 px-4 py-2 text-center
                text-white
                hover:bg-gray-500
              `}
            >
              Choose MusicXML File
            </label>
            <span className="text-gray-700">{fileName}</span>
            <input
              id="file-upload"
              type="file"
              className="hidden"
              onChange={handleFileChange}
              accept=".xml, .XML, .musicxml, .MUSICXML, .mxl, .MXL, application/octet-stream"
            />
            <button
              onClick={uploadFile}
              className={`
                w-3xs rounded bg-yellow-500 px-4 py-2 text-white
                hover:bg-yellow-600
              `}
            >
              Upload File
            </button>
            {status && <p className="italic">Status: {status}</p>}
          </div>

          {/* Information Section */}
          <div className="flex w-5/6 flex-col gap-y-2 text-gray-600">
            <p>
              Upload a MusicXML sheet music file to get fingering suggestions
              for 2-hand piano music. The process typically takes around 3
              minutes.
            </p>
            <p>Supported file formats: .xml, .musicxml, .mxl</p>
            <p>
              <Link
                to="/musicxml-resource"
                className="text-orange-500 underline"
              >
                How to get MusicXML files?
              </Link>
            </p>
            <p className="mt-3 font-bold">How to use:</p>
            <p>
              1. Grey fingerings are alternate. Click to swap with main
              fingerings, or hold to swap all in the group.
            </p>
            <p>2. Black fingerings are main. Drag to edit if needed.</p>

            <p className="mt-3">
              We are actively improving our product! Please email us at{' '}
              <a
                href="mailto:auto.fingering@outlook.com"
                className="text-orange-600 underline hover:text-blue-800"
              >
                auto.fingering@outlook.com
              </a>{' '}
              or join our{' '}
              <a
                href={socialLinks.discordServer}
                className="text-orange-600 underline hover:text-blue-800"
              >
                Discord server
              </a>{' '}
              to let us know if you have any thoughts!
            </p>

            {/* Subscription Status */}
            {subscriptionStatus ? (
              subscriptionStatus.status === 'active' ? (
                <div className="m-8 rounded-lg bg-green-100 p-4">
                  <p className="text-gray-700">
                    You are subscribed to{' '}
                    <span className="font-bold text-green-700">Premium</span>{' '}
                    Plan! Enjoy unlimited access!
                  </p>
                </div>
              ) : (
                <div className="mt-8 rounded-lg bg-yellow-100 p-4">
                  <p className="text-gray-700">
                    You are on{' '}
                    <span className="font-bold text-yellow-700">Demo</span>{' '}
                    plan. Subscribe to enjoy unlimited access!
                  </p>
                </div>
              )
            ) : (
              <div className="mt-8 rounded-lg bg-gray-100 p-4">
                <p className="text-gray-700">Fetching subscription status...</p>
              </div>
            )}

            {/* Alternative Fingerings Toggle */}
            {fileXML.length !== 0 && (
              <div className="flex flex-col items-center gap-y-3">
                <button
                  ref={buttonRef}
                  className={`
                    rounded bg-yellow-500 px-4 py-2 text-white
                    hover:bg-yellow-600
                  `}
                >
                  Show / Hide Alternative Fingerings
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Sheet Music Display */}
        <div className="my-4 min-h-64 w-11/12 min-w-max">
          <div ref={osmdContainerRef}></div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export const AutoFingeringWithMsal = () => {
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <AutoFingering />
    </MsalAuthenticationTemplate>
  );
};
