import { useEffect } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { useSubscriptionStatus } from '../../hooks/useSubscriptionStatus';
import { Checkout } from '../../components/Checkout';
import { CustomerPortal } from '../../components/CustomerPortal';
import { Footer } from '../../components/Footer';

export function Subscription() {
  // const [errorState, setErrorState] = useState(null);
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  // Set the title of the page
  useEffect(() => {
    document.title = 'Subscription - PineappleMusicLab';
  }, []);

  // Fetch subscription status
  const { subscriptionStatus } = useSubscriptionStatus(isAuthenticated);

  return (
    <div
      className={`
        bg-gray-25 flex min-h-screen flex-col items-center p-4 font-[Outfit]
      `}
    >
      <header className="mb-16 text-center">
        <h1 className="mt-8 mb-4 text-5xl font-bold text-gray-900">Pricing</h1>
      </header>

      <div className="flex w-full max-w-5xl flex-row justify-between">
        <div
          className={`w-1/3 rounded-lg border border-solid border-gray-300 p-4`}
        >
          <h2 className="my-4 pl-2 text-xl font-semibold text-gray-800">
            Demo
          </h2>
          <h1 className="my-4 pl-2 text-3xl font-semibold text-gray-800">
            $0 / month
          </h1>
          <p className="p-2 text-xl text-gray-500">
            Generate and edit fingering for up to 18 measures.
          </p>
        </div>

        <div
          className={`w-1/3 rounded-lg border border-solid border-gray-300 p-4`}
        >
          <h2 className="my-4 pl-2 text-xl font-semibold text-gray-800">
            Premium
          </h2>
          <h1 className="my-4 pl-2 text-3xl font-semibold text-gray-800">
            $5 / month
          </h1>
          <p className="p-2 text-xl text-gray-500">
            Generate and edit fingering without length limit.
          </p>
        </div>
      </div>

      <div className="m-8 h-px w-full max-w-5xl bg-gray-300"></div>

      {isAuthenticated &&
        (subscriptionStatus ? (
          <div className="m-2 mb-8 w-full max-w-5xl rounded-lg">
            <h3 className="text-2xl font-semibold text-yellow-500">
              Welcome back!
            </h3>
            <p className="text-2xl text-gray-700">
              Your current plan is:
              <span className="ml-1 font-semibold text-gray-800">
                {subscriptionStatus.status === 'active' ? 'Premium' : 'Demo'}
              </span>
            </p>
            <div className="mb-8 flex w-full gap-6">
              {subscriptionStatus.status !== 'active' && <Checkout />}
              <CustomerPortal />
            </div>
          </div>
        ) : (
          <p className="mt-8 mb-24 text-2xl text-gray-700">
            Fetching subscription status...
          </p>
        ))}

      {!isAuthenticated && (
        <div>
          <button
            onClick={async () => await instance.loginRedirect()} // using the same handler in useAuth() has issues of triggering two events
            className={`
              mt-6 rounded-lg bg-yellow-500 px-8 py-3 text-2xl font-semibold
              text-white shadow-md
              hover:bg-yellow-600
              focus:outline-hidden
            `}
          >
            Log in to subscribe
          </button>
        </div>
      )}
      <div className="mt-8 min-h-64 text-2xl text-gray-700" />
      <Footer />
    </div>
  );
}
