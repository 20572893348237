import { AxiosError, isAxiosError } from 'axios';
import { Footer } from './Footer';
import { useNavigate } from 'react-router-dom';

export function ErrorState({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) {
  const navigate = useNavigate();

  // Check if the error is an Axios error and handle it accordingly
  const renderError = (error: AxiosError | Error) => {
    console.log('Error:', error);
    if (isAxiosError(error)) {
      console.log('Axios error detected:', error);
      return (
        <pre className="text-wrap">
          {JSON.stringify(
            {
              message: error.message,
              status: error.response?.status,
              data: error.response?.data,
            },
            null,
            2
          )}
        </pre>
      );
    } else {
      return (
        <pre className="text-wrap">
          {JSON.stringify(
            {
              message: error.message,
            },
            null,
            2
          )}
        </pre>
      );
    }
  };

  return (
    <>
      <div className="my-6 flex flex-col items-center font-[Outfit]">
        <div
          className={`flex w-3/4 flex-col items-center gap-y-8 text-gray-600`}
        >
          <div className="rounded-lg bg-white p-8 text-center">
            <h2 className="mb-4 text-2xl font-bold text-red-600">Error</h2>
            <p className="mb-4 text-gray-700">
              Sorry, something happened on our end. Please try refreshing the
              page and try again. If the issue continues to exist, please
              contact us.
            </p>
            <p className="mb-4 w-5xl text-left text-gray-700">
              <strong>Error Details:</strong> {renderError(error)}
            </p>
            <button
              onClick={() => {
                resetErrorBoundary();
                navigate('/');
              }}
              className={`
                rounded bg-yellow-500 px-4 py-2 text-white
                hover:bg-yellow-600
              `}
            >
              To the Home Page
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
