/**
 * Update the content of a fingering label
 * @param label
 * @param content
 */
const updateFingeringLabelContent = (label: Element, content: string) => {
  if (!label) {
    console.log('label not provided');
  }
  if (label.children[0]) {
    label.children[0].textContent = content;
  } else {
    label.textContent = content;
  }
};

export { updateFingeringLabelContent };
