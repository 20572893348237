import { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { loginRequest } from '../authConfig';
import { getApiHost } from '../utils/getApiHost';
import { useErrorBoundary } from 'react-error-boundary';

export interface SubscriptionStatus {
  current_period_end: string;
  status: string;
  cancel_at_period_end: boolean;
}

export const useSubscriptionStatus = (isAuthenticated: boolean) => {
  const [statusLoading, setStatusLoading] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] =
    useState<SubscriptionStatus | null>(null);
  const { instance, inProgress } = useMsal(); // inProgress is to track the login state
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    // Check if MSAL is still in progress (i.e., the user is being redirected)
    if (inProgress === 'none' && isAuthenticated) {
      const fetchSubscriptionStatus = async () => {
        try {
          setStatusLoading(true);
          const authResult = await instance.acquireTokenSilent({
            ...loginRequest,
          });
          const apiUrl = getApiHost();

          const response = await axios.get(
            `${apiUrl}/user/subscription-status`,
            {
              headers: {
                Authorization: `Bearer ${authResult.idToken}`,
              },
            }
          );

          const data = await response.data;
          data.current_period_end = new Date(
            data.current_period_end * 1000
          ).toLocaleDateString();
          setSubscriptionStatus(data);
        } catch (err: any) {
          showBoundary(err); // Show the error in the error boundary
        } finally {
          setStatusLoading(false);
        }
      };

      fetchSubscriptionStatus();
    }
  }, [instance, isAuthenticated, inProgress, showBoundary]);

  return {
    subscriptionStatus,
    statusLoading,
    setSubscriptionStatus,
  };
};
