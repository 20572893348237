import { GraphicalStaffEntry, GraphicalLabel } from 'opensheetmusicdisplay';

/**
 * Get one fingering label element in all the labels of a staff entry
 * @param osmdStaffEntry  The GraphicalStaffEntry object
 * @param labelIndex  The index of the the label in staffEntry.FingeringEntries.SVGNode
 * @returns
 */
const getFingeringLabel = (
  osmdStaffEntry: GraphicalStaffEntry,
  labelIndex: number
) => {
  try {
    // FingeringEntries denotes the existing fingering entry for the current staff entry
    // the array only contains 1 element that consists all fingering data
    const label: GraphicalLabel = osmdStaffEntry.FingeringEntries[0];
    if (!label) {
      console.log('label not found', {
        labelIndex,
      });
      return null;
    }
    // get the svg element that represents the current label
    const svgElement = label.SVGNode as SVGElement;
    // the children of said svg element contains the fingering text
    // if labelIndex is 0, return the svgElement itself, to prevent returning text content
    if (labelIndex == 0) {
      return svgElement;
    }
    return svgElement.children[labelIndex] as SVGElement;
  } catch (e) {
    console.error('Error getting fingering label:', e);
    return null;
  }
};

export { getFingeringLabel };
