import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorState } from './components/ErrorState';

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
    {
      onUncaughtError: (error, errorInfo) => {
        console.warn('Uncaught error:', error, errorInfo.componentStack);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onCaughtError: (_error) => {
        // do not log errors handled by error boundaries
      },
    }
  );
  root.render(
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={ErrorState}>
        <App msalInstance={msalInstance} />
      </ErrorBoundary>
    </BrowserRouter>
  );
});
