import { StaffEntry } from '../types/types';

/**
 * Swap main fingering and alternative fingerings of a staffEntry in the fingering data
 * @param staffEntryData the fingering data passed from the backend
 */
const swapFingeringData = (staffEntryData: StaffEntry) => {
  console.log('swapFingeringData', staffEntryData);
  if (!staffEntryData.altFinger) return;
  [staffEntryData.mainFinger, staffEntryData.altFinger] = [
    staffEntryData.altFinger,
    staffEntryData.mainFinger,
  ];
  [staffEntryData.mainCritical, staffEntryData.altCritical] = [
    staffEntryData.altCritical,
    staffEntryData.mainCritical,
  ];
};

export { swapFingeringData };
