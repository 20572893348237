import { Routes, Route, useNavigate } from 'react-router-dom';

// MSAL imports

import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { CustomNavigationClient } from './utils/NavigationClient';

import { AutoFingeringWithMsal } from './pages/Autofingering';
import { Home } from './pages/Home';
import { MusicxmlResource } from './pages/MusicxmlResource';
import { Subscription } from './pages/Subscription/Subscription';
import NavBar from './components/NavBar/NavBar';

const App = ({ msalInstance }: { msalInstance: IPublicClientApplication }) => {
  return (
    <MsalProvider instance={msalInstance}>
      <NavBar />
      <Pages />
    </MsalProvider>
  );
};

function Pages() {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  const { instance } = useMsal();
  instance.setNavigationClient(navigationClient);
  return (
    <Routes>
      <Route path="/app" element={<AutoFingeringWithMsal />} />
      <Route path="/subscription" element={<Subscription />} />
      <Route path="/musicxml-resource" element={<MusicxmlResource />} />
      <Route path="/" element={<Home />} />
    </Routes>
  );
}

export default App;
