import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faVimeo,
  faDiscord,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { socialLinks } from 'src/socialLinks';

function Footer() {
  return (
    <>
      <div className="h-px w-full bg-gray-300"></div>
      <footer className="bg-gray-25 px-4 py-10 font-[Outfit] text-gray-800">
        <div
          className={`
            mx-auto grid max-w-6xl grid-cols-1 gap-8 text-center
            md:grid-cols-3
          `}
        >
          {/* Community Section */}
          <div>
            <h3 className="mb-2 text-lg font-semibold">Community</h3>
            <p>
              <a
                href={socialLinks.discordServer}
                className="text-base hover:underline"
              >
                Join Our Discord Server
              </a>
            </p>
          </div>

          {/* Contact Us Section */}
          <div className="flex flex-col items-center">
            <h3 className="mb-2 text-lg font-semibold">Contact Us</h3>
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faEnvelope} size="lg" />
              <a
                href="mailto:auto.fingering@outlook.com"
                className="text-base hover:underline"
              >
                auto.fingering@outlook.com
              </a>
            </div>
          </div>

          {/* Social Media Section */}
          <div>
            <h3 className="mb-2 text-lg font-semibold">Social Media</h3>
            <div className="flex justify-center gap-4">
              <a
                href={socialLinks.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-800 hover:text-yellow-500"
              >
                <FontAwesomeIcon icon={faInstagram} size="lg" />
              </a>
              <a
                href={socialLinks.vimeo}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-800 hover:text-yellow-500"
              >
                <FontAwesomeIcon icon={faVimeo} size="lg" />
              </a>
              <a
                href={socialLinks.discordServer}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-800 hover:text-yellow-500"
              >
                <FontAwesomeIcon icon={faDiscord} size="lg" />
              </a>
            </div>
          </div>
        </div>

        {/* Copyright Section */}
        <div className="mt-8 text-center text-gray-400">
          &copy; {new Date().getFullYear()} PineappleMusicLab. All rights
          reserved.
        </div>
      </footer>
    </>
  );
}

export { Footer };
