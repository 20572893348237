import axios from 'axios';
import { getApiHost } from '../utils/getApiHost';
import { loginRequest } from '../authConfig';
import { useMsal } from '@azure/msal-react';
import { useErrorBoundary } from 'react-error-boundary';

const Checkout = () => {
  // const [customerId, setCustomerId] = useState(null);
  const { showBoundary } = useErrorBoundary();
  const { instance } = useMsal();

  const handleCheckout = async () => {
    try {
      const authResult = await instance.acquireTokenSilent({
        ...loginRequest,
      });
      const apiUrl = getApiHost();

      const response = await axios.post(
        `${apiUrl}/user/subscribe`,
        {
          success_url: `${window.location.href}`,
          cancel_url: `${window.location.href}`,
        },
        {
          headers: {
            Authorization: `Bearer ${authResult.idToken}`,
          },
        }
      );

      const data = await response.data;
      const checkoutSessionUrl = data.checkout_session_url;
      // Redirect to the Stripe Checkout page
      window.location.href = checkoutSessionUrl;
      // setCustomerId(data.customer_id); // Update state with the customer ID
    } catch (err: any) {
      showBoundary(err);
    }
  };

  return (
    <div>
      <button
        onClick={handleCheckout}
        className={`
          mt-6 rounded-lg border border-solid border-yellow-500 bg-yellow-500
          px-8 py-3 text-2xl font-semibold text-white
          hover:bg-yellow-600
          focus:outline-hidden
        `}
      >
        Subscribe!
      </button>
    </div>
  );
};

export { Checkout };
