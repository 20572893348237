import { GraphicalStaffEntry } from 'opensheetmusicdisplay';
import { StaffEntry } from '../types/types';
import { getFingeringLabel } from './getFingeringLabel';

/**
 * Function to update the color of fingering labels from a given index range for a staff entry
 * (Given that each staff entry contain a list of labels that within the range given)
 * @param osmdStaffEntry an staffEntry object from the OSMD
 * @param staffIndex
 * @param color - new color for the labels
 * @param entryData - the fingering data for the staff entry
 */
const updateEntryFingerColor = (
  osmdStaffEntry: GraphicalStaffEntry,
  staffIndex: number,
  color: string,
  entryData: StaffEntry
) => {
  //TODO: simply logic within this function
  let idxStart = 0;
  let idxEnd = entryData.mainFinger.length;
  if (staffIndex === 1) {
    idxStart = entryData.mainFinger.length;
    idxEnd = entryData.mainFinger.length * 2;
  }

  for (let i = idxStart; i < idxEnd; i++) {
    const labelIndex = i;
    const label = getFingeringLabel(osmdStaffEntry, labelIndex);
    if (!label) {
      console.log('No label found', {
        staffIndex,
        labelIndex,
      });
      return;
    }

    if (label) {
      label.setAttribute('fill', color);
      if (label.children[0]) {
        label.children[0].setAttribute('fill', color);
      }
    }
  }
};

export { updateEntryFingerColor };
