import { useCallback, useEffect, useState } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { AuthenticationResult, EventType } from '@azure/msal-browser';
import axios from 'axios';
import { getApiHost } from '../../utils/getApiHost';
import { b2cPolicies } from '../../authConfig';
import { useErrorBoundary } from 'react-error-boundary';

interface IdTokenClaims {
  tfp?: string;
  // Add other properties as needed
}

export const useAuth = () => {
  const [name, setName] = useState<string>();
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { showBoundary } = useErrorBoundary();

  const confirmUserSignUp = useCallback(async (token: string) => {
    console.log('confirmUserSignUp called');
    console.trace();
    const apiUrl = getApiHost();
    try {
      await axios.post(
        `${apiUrl}/user/signup`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      showBoundary(error);
    }
  }, []);

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      console.log(event);

      const handleEvent = async () => {
        if (
          (event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            event.eventType === EventType.SSO_SILENT_SUCCESS) &&
          event.payload
        ) {
          const account = (event.payload as AuthenticationResult).account;
          instance.setActiveAccount(account);

          if (event.eventType === EventType.LOGIN_SUCCESS) {
            const payload = event.payload as AuthenticationResult;
            console.log('event login success', payload);
            if (
              (payload.idTokenClaims as IdTokenClaims).tfp ===
              b2cPolicies.names.signUpSignIn
            ) {
              // const account = instance.getActiveAccount();
              // if (!account) {
              //   console.warn('No active account after login request, this should not happen');
              //   return;
              // }
              // console.log('current active account in event handler', account);
              confirmUserSignUp(payload.idToken);
            }
          }
        }

        if (event.eventType === EventType.SSO_SILENT_SUCCESS && event.payload) {
          const payload = event.payload as AuthenticationResult;
          if (payload.account) {
            console.log('silent login success', payload);
          }
        }

        if (event.eventType === EventType.LOGOUT_SUCCESS) {
          console.log('logout success', event);
          // setUserInfo(undefined);
        }
      };

      handleEvent();
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, confirmUserSignUp]);

  useEffect(() => {
    if (isAuthenticated) {
      const account = instance.getActiveAccount();
      if (account) {
        setName(account.name);
      }
    }
  }, [isAuthenticated, instance]);

  const handleLogin = async () => {
    console.log('handle login called');
    try {
      await instance.loginRedirect();
      // setLoading(true);
    } catch (error) {
      console.error('Login failed', error);
      showBoundary(error);
    }
  };

  const handleLogout = () => {
    instance.logoutRedirect({ postLogoutRedirectUri: '/' });
  };

  return { handleLogin, handleLogout, inProgress, name, isAuthenticated };
};
