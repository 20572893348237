import {
  BrowserCacheLocation,
  Configuration,
  LogLevel,
} from '@azure/msal-browser';
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

/**
 * Enter here the user flows and custom policies for your B2C application
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1_signupsignin1',
  },
  authorities: {
    signUpSignIn: {
      authority:
        'https://pineapplemusic.b2clogin.com/pineapplemusic.onmicrosoft.com/B2C_1_signupsignin1',
    },
  },
  authorityDomain: 'pineapplemusic.b2clogin.com',
};

const redirectUri = () => {
  return window.location.origin;
};

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: 'ae219c1d-42da-4e6d-abed-b023643dc673',
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: redirectUri(),
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    // allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        const { hostname } = window.location;
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            if (hostname === 'localhost') {
              console.info(message);
            }
            return;
          case LogLevel.Verbose:
            if (hostname === 'localhost') {
              console.debug(message);
            }
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

// Scopes you add here will be prompted for consent during login
export const loginRequest = {
  // scopes: ['openid', 'profile', 'offline_access'],
  scopes: ['profile'],
};

/**
 * Enter here the coordinates of your web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const apiConfig = {
  scopes: ['https://msidlabb2c.onmicrosoft.com/msidlabb2capi/read'],
  uri: 'https://msidlabb2c.onmicrosoft.com/msidlabb2capi',
};
