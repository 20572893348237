const decodeBase64Utf8 = (base64Str: string): string => {
  try {
    return decodeURIComponent(
      atob(base64Str)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
  } catch (e) {
    console.error('Failed to decode base64 string:', e);
    return '';
  }
};

export { decodeBase64Utf8 };
