/**
 * Generates a new fingering string based on the dragged position.
 * @param {number} xChange - The change in the x-coordinate.
 * @param {number} yChange - The change in the y-coordinate.
 * @param {string | null} originalFingerStr - The original fingering string.
 * @returns {string} The new fingering string.
 */
const getDraggedFingering = (
  xChange: number,
  yChange: number,
  originalFingerStr: string | null
): string => {
  const pxPerStep = 50;
  //oringinal finger is a string, number with dashes, for example "2-3-5"
  //get the list of numbers from the string, and their index in the original string
  const numList: number[] = [];
  const indexList: number[] = [];
  if (originalFingerStr) {
    let currentIndex = 0;
    const parts = originalFingerStr.split('-');
    for (const part of parts) {
      numList.push(Number(part));
      indexList.push(currentIndex);
      currentIndex += part.length + 1; // +1 for the dash
    }
  }

  // get the index of the number that is being dragged
  const zeroX = -0.5 * pxPerStep * numList.length;
  let draggedIndex = Math.floor((xChange - zeroX) / pxPerStep);
  if (draggedIndex < 0) {
    draggedIndex = 0;
  } else if (draggedIndex >= numList.length) {
    draggedIndex = numList.length - 1;
  }

  // update the number that is being dragged
  const originalFingerNum = numList[draggedIndex];
  // devide by pxPerStep and divide by 6 to get the remainder
  let fingering = (originalFingerNum - 1 - Math.round(yChange / pxPerStep)) % 5;
  // if the remainder is negative, add 5 to it
  if (fingering < 0) {
    fingering += 5;
  }
  //get the new fingering string
  let newFingerStr = '';
  for (let i = 0; i < numList.length; i++) {
    if (i === draggedIndex) {
      newFingerStr += fingering + 1;
    } else {
      newFingerStr += numList[i];
    }
    if (i < numList.length - 1) {
      newFingerStr += '-';
    }
  }

  return newFingerStr;
};

/**
 * Creates a div element for the label's text content and positions it at the given location.
 * @param {Object} position - The position object containing pageX and pageY.
 * @param {string} originalFingering - The original fingering text content.
 * @returns {HTMLDivElement} The created letter element.
 */
const createLetterElement = (
  position: { pageX: number; pageY: number },
  originalFingering: string
): HTMLDivElement => {
  const letterElement = document.createElement('div');
  letterElement.id = 'temp-letter-a'; // Assign an ID to the element for easy removal
  letterElement.style.position = 'absolute';
  letterElement.style.top = `${position.pageY - 50}px`;
  letterElement.style.left = `${position.pageX - 50}px`;
  letterElement.style.fontSize = '32px';
  letterElement.style.fontWeight = 'bold';
  letterElement.style.color = '#6086cc';
  letterElement.innerText = originalFingering || ''; // Show the initial label text content
  document.body.appendChild(letterElement);
  return letterElement;
};

/**
 * Creates vertical dashed line elements centered at the given position.
 * @param {Object} position - The position object containing pageX and pageY.
 * @param {number} numLength - The number of lines to create.
 */
const createLineElements = (
  position: { pageX: number; pageY: number },
  numLength: number
) => {
  for (let i = 1; i < numLength; i++) {
    const lineElement = document.createElement('div');
    lineElement.className = 'temp-line'; // Assign class name for easy removal
    lineElement.style.position = 'absolute';
    lineElement.style.top = `${position.pageY - 100}px`;
    lineElement.style.left = `${position.pageX + (i - numLength / 2) * 50}px`;
    lineElement.style.width = '1px';
    lineElement.style.height = '200px';
    lineElement.style.borderLeft = '1px dashed #6086cc';
    document.body.appendChild(lineElement);
  }
};

/**
 * Removes all elements with the specified class name from the document.
 * @param {string} className - The class name of the elements to remove.
 */
const removeElementsByClassName = (className: string) => {
  const elements = document.getElementsByClassName(className);
  while (elements.length > 0) {
    elements[0].remove();
  }
};

export {
  getDraggedFingering,
  createLetterElement,
  createLineElements,
  removeElementsByClassName,
};
