import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../components/Footer';
import { socialLinks } from 'src/socialLinks';

function Home() {
  const [isMobile, setIsMobile] = useState(false);
  const [isBannerVisible, setIsBannerVisible] = useState(true);

  useEffect(() => {
    document.title = 'PineappleMusicLab';

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isMobile && isBannerVisible && (
        <div
          className={`
            mobile-prompt fixed top-0 right-0 left-0 z-50 bg-yellow-500 px-8
            py-4 text-center font-[Outfit] font-semibold text-white
          `}
        >
          For the best experience, we recommend you to use a tablet or desktop
          device.
          <button
            onClick={() => setIsBannerVisible(false)}
            className="absolute top-4 right-4 text-white"
          >
            &times;
          </button>
        </div>
      )}
      <div
        className={`
          bg-gray-25 flex min-h-screen flex-col items-center p-4 font-[Outfit]
        `}
      >
        <header className="mb-16 text-center">
          <h1
            className={`
              mt-6 mb-4 font-bold text-gray-800
              sm:text-2xl
              lg:text-5xl
            `}
          >
            AI Piano Fingering Suggestion
          </h1>
          <p className="mx-auto max-w-2xl text-gray-700 sm:text-xl lg:text-2xl">
            AI-generated customizable piano fingering suggestions for you to get
            a head start on a new piece.
          </p>
          <button
            className={`
              mt-6 rounded-lg bg-yellow-500 px-8 py-3 font-semibold text-white
              hover:bg-yellow-600
              sm:text-xl
              lg:text-2xl
            `}
          >
            <Link to="/app">Get Started for Free!</Link>
          </button>
        </header>

        <div className="video-container mb-4 w-full max-w-5xl">
          <div className="relative aspect-video w-full overflow-hidden">
            <iframe
              className="absolute inset-0 h-full w-full"
              src={socialLinks.demoVideo}
              title="AI Piano Fingering Generation - Pineapple Music Lab"
              style={{ border: 'none' }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        <div className="mt-4 h-px w-full max-w-5xl bg-gray-300"></div>

        <div className="mt-6 w-full max-w-5xl p-5 text-center">
          <h2
            className={`
              mb-6 pl-2 font-semibold text-gray-800
              sm:text-xl
              lg:text-3xl
            `}
          >
            About the Mission
          </h2>
          <p className="p-2 text-xl text-gray-500">
            We aim to help pianists of all levels achieve their musical goals
            better with innovative AI technologies.
          </p>
        </div>

        <div className="mt-2 w-full max-w-5xl p-5 text-center">
          <h2
            className={`
              mb-6 pl-2 font-semibold text-gray-800
              sm:text-xl
              lg:text-3xl
            `}
          >
            What You Can Get From It
          </h2>
          <p className="p-2 text-xl text-gray-500">
            Save time starting a new piece: Derive from AI suggested fingerings
            instead of starting from scratch.
            <br />
            See different possibilities: Trained with data labeled by piano
            pros, our AI can give you cool ideas.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
}

export { Home };
