import { useEffect } from 'react';
import { Footer } from '../components/Footer';
import { socialLinks } from 'src/socialLinks';

function MusicxmlResource() {
  useEffect(() => {
    document.title = 'Get MusicXML - PineappleMusicLab';
  }, []);

  return (
    <>
      <div className="mx-auto max-w-3xl p-6 font-sans">
        <h1 className="my-4 text-4xl font-bold text-gray-900">
          How to Get MusicXML Files
        </h1>

        <h2 className="my-4 text-2xl font-bold text-gray-900">
          Download a Sample MusicXML to Try Our Product
        </h2>

        <p className="my-4 text-lg leading-relaxed text-gray-700">
          <a
            className="text-orange-600 underline hover:text-blue-800"
            href={`https://drive.google.com/uc?export=download&id=12j01Oiw-aVtOpiIk3StwPRMbwzJOCopB`}
            download
          >
            Fur Elise - Measure 0-8.musicxml
          </a>
          <br />
          <a
            className="text-orange-600 underline hover:text-blue-800"
            href={`https://drive.google.com/uc?export=download&id=14_gEt2MxAh7mZHgCA_3DnYRM4i_gC8zm`}
            download
          >
            Flight of the Bumblebee - Measure 59-66.musicxml
          </a>
        </p>

        <h2 className="my-4 text-2xl font-bold text-gray-900">
          Get MusicXML Through Our Community
        </h2>

        <p className="my-4 text-lg leading-relaxed text-gray-700">
          Join our{' '}
          <a
            href={socialLinks.discordServer}
            className="text-orange-600 underline hover:text-blue-800"
          >
            Discord server
          </a>{' '}
          to ask for help, or email us at{' '}
          <a
            href="mailto:auto.fingering@outlook.com"
            className="text-orange-600 underline hover:text-blue-800"
          >
            auto.fingering@outlook.com
          </a>{' '}
          with your sheet music. We can help you to obtain a MusicXML file based
          on our availability! If you have any questions or need assistance,
          feel free to reach out!
        </p>

        <h2 className="my-4 text-2xl font-bold text-gray-900">
          From Music Notation Softwares
        </h2>

        <p className="text-lg leading-relaxed text-gray-700">
          Most music notation softwares such as MuseScore, Finale, Sibelius, and
          Dorico can export your sheet music in MusicXML format.
        </p>

        <h2 className="my-4 text-2xl font-bold text-gray-900">
          Online Sources for MusicXML Files
        </h2>

        <p className="text-lg leading-relaxed text-gray-700">
          There are several resources available online to find and download
          MusicXML files of sheet music:
        </p>

        <h2 className="my-4 text-lg font-bold text-gray-900">
          1. MuseScore (
          <a
            href="https://musescore.com"
            className="text-orange-600 underline hover:text-blue-800"
          >
            https://musescore.com
          </a>
          )
        </h2>

        <p className="my-4 text-lg leading-relaxed text-gray-700">
          - MuseScore offers a vast library of sheet music, much of which is
          available in MusicXML format. <br></br>- Some files require a Pro
          membership for download. <br></br>
        </p>

        <h2 className="my-4 text-lg font-bold text-gray-900">
          2. Musicalion (
          <a
            href="https://www.musicalion.com"
            className="text-orange-600 underline hover:text-blue-800"
          >
            https://www.musicalion.com
          </a>
          )
        </h2>

        <p className="my-4 text-lg leading-relaxed text-gray-700">
          - A massive repository of sheet music, many are available in MusicXML
          format. <br></br>- Downloading MusicXML may require a subscription.{' '}
          <br></br>
        </p>

        <h2 className="my-4 text-lg font-bold text-gray-900">
          3. Virtual Sheet Music (
          <a
            href="https://www.virtualsheetmusic.com"
            className="text-orange-600 underline hover:text-blue-800"
          >
            https://www.virtualsheetmusic.com
          </a>
          )
        </h2>

        <p className="my-4 text-lg leading-relaxed text-gray-700">
          - Offers digital sheet music with some MusicXML options available.{' '}
          <br></br>- Exporting MusicXML from playground may require a paid
          membership. <br></br>
        </p>

        <h2 className="my-4 text-2xl font-bold text-gray-900">
          Convert Sheet Music to MusicXML
        </h2>

        <p className="my-4 text-lg leading-relaxed text-gray-700">
          If you have sheet music in a different format (such as PDF or image
          files), you can use Optical Music Recognition (OMR) software to
          convert it to MusicXML. Here are a few tools that may help:
        </p>

        <h2 className="my-4 text-lg font-bold text-gray-900">
          1. Audiveris (
          <a
            href="https://github.com/Audiveris/audiveris"
            className="text-orange-600 underline hover:text-blue-800"
          >
            https://github.com/Audiveris/audiveris
          </a>
          )
        </h2>

        <p className="my-4 text-lg leading-relaxed text-gray-700">
          - Free open-source OMR software. <br></br>- Installer only available
          on Windows, macOS version needs to be built from source. <br></br>
        </p>

        <h2 className="my-4 text-lg font-bold text-gray-900">
          2. Newzik (
          <a
            href="https://newzik.com/en/app/"
            className="text-orange-600 underline hover:text-blue-800"
          >
            https://newzik.com/en/app/
          </a>
          )
        </h2>

        <p className="my-4 text-lg leading-relaxed text-gray-700">
          - The app has OMR feature <br></br>- To use the feature may need
          subscription. <br></br>
        </p>

        <h2 className="my-4 text-lg font-bold text-gray-900">
          3. PlayScore 2 (
          <a
            href="https://www.playscore.co/"
            className="text-orange-600 underline hover:text-blue-800"
          >
            https://www.playscore.co/
          </a>
          )
        </h2>

        <p className="my-4 text-lg leading-relaxed text-gray-700">
          - The app has OMR feature <br></br>- To use the feature may need
          subscription. <br></br>
        </p>

        <h2 className="my-4 text-2xl font-bold text-gray-900">Disclaimer</h2>

        <p className="my-4 text-lg leading-relaxed text-gray-700">
          The resources listed above are third-party services, and we do not
          own, control, or guarantee the availability, accuracy, or legality of
          their content. Please ensure that you comply with any licensing
          agreements and terms of use associated with these sources before
          downloading or using any MusicXML files. We are not responsible for
          any issues that may arise from using third-party content.
        </p>
      </div>

      <Footer />
    </>
  );
}

export { MusicxmlResource };
