import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { Dispatch, SetStateAction, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { FingeringData } from '../types/types';
import { decodeBase64Utf8 } from '../utils/decodeBase64Utf8';
import { OpenSheetMusicDisplay } from 'opensheetmusicdisplay';
import { getApiHost } from '../utils/getApiHost';
import { loginRequest } from '../authConfig';

const useFileUpload = (
  osmd: OpenSheetMusicDisplay | null,
  setOsmd: Dispatch<SetStateAction<OpenSheetMusicDisplay | null>>
) => {
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [fileXML, setProcessedFileData] = useState<string>('');
  const [fingering, setFingerData] = useState<FingeringData>([]);
  const { instance } = useMsal();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      if (file) {
        console.log('File uploaded:', file.name, file.type);
        if (file.name.endsWith('.mxl') || file.type === 'application/zip') {
          console.log('MXL file detected.');
          // Process .mxl file
        }
      }
      setFile(event.target.files[0]);
      setFileName(event.target.files[0].name);
    }
  };

  const uploadFile = async () => {
    if (!file) {
      alert('Please select a file first!');
      return;
    }

    if (osmd) {
      setOsmd(null);
      setFingerData([]);
      setProcessedFileData('');
      setStatus('');
    }

    const id = uuidV4();
    const formData = new FormData();

    formData.append('file', file);
    formData.append('uuid', id);

    setStatus('Processing...');

    const authResult = await instance.acquireTokenSilent({
      ...loginRequest,
    });
    const apiUrl = getApiHost();

    const headers = {
      Authorization: `Bearer ${authResult.idToken}`, // Add the Authorization header
      'Content-Type': 'multipart/form-data', // Explicitly set content type
    };
    try {
      const response = await axios.post(
        `${apiUrl}/fingering/upload`,
        formData,
        { headers } // Pass headers as the third argument
      );
      const encodedXml = response.data.file;
      const decodedXml = decodeBase64Utf8(encodedXml);
      setProcessedFileData(decodedXml);
      setFingerData(response.data.fingerData);
      setStatus('File uploaded and processed successfully.');
    } catch (error) {
      setStatus(`Error uploading file: ${error}`);
    }
  };

  return { fileXML, fingering, status, handleFileChange, uploadFile, fileName };
};

export { useFileUpload };
