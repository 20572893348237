import { InteractionStatus } from '@azure/msal-browser';
import { Link } from 'react-router-dom';
import { useAuth } from './useAuth';
import { AuthenticatedTemplate } from '@azure/msal-react';
import logo from '@Assets/images/logo-80.png';
import { useState } from 'react';

const NavBar = () => {
  const { handleLogin, handleLogout, inProgress, isAuthenticated, name } =
    useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const renderSignInSignOutButton = () => {
    if (isAuthenticated) {
      return (
        <button
          onClick={handleLogout}
          className={`
            rounded border-2 border-solid border-gray-800 bg-transparent px-5
            py-1 text-gray-800
            hover:bg-yellow-400 hover:text-black
          `}
        >
          Log Out
        </button>
      );
    }

    if (
      inProgress !== InteractionStatus.Startup &&
      inProgress !== InteractionStatus.HandleRedirect
    ) {
      return (
        <button
          onClick={handleLogin}
          className={`
            rounded border-2 border-solid border-gray-800 bg-transparent px-5
            py-1 text-gray-800
            hover:bg-yellow-400 hover:text-black
          `}
        >
          Log In
        </button>
      );
    }

    return null;
  };

  return (
    <>
      <nav className="bg-gray-25 border-b-2 border-gray-300 p-4 font-[Outfit]">
        <div className="flex items-center justify-between">
          {/* Logo and Title on the Left */}
          <div className="flex items-end space-x-2">
            <Link to="/">
              <img src={logo} alt="Logo" className="h-10 md:h-10 lg:h-12" />
            </Link>
            <div
              className={`
                mb-1 text-2xl font-medium text-gray-800
                sm:text-2xl
                md:text-3xl
                lg:text-4xl
              `}
            >
              <Link to="/" className="text-gray-800 hover:text-yellow-500">
                PineappleMusicLab
              </Link>
            </div>
          </div>

          {/* Hamburger Menu for Mobile */}
          <div className="lg:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-800 focus:outline-hidden"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>

          {/* Navigation and Auth Buttons on the Right */}
          <div
            className={`
              hidden items-center
              md:space-x-2 md:text-base
              lg:flex lg:space-x-4 lg:text-lg
            `}
          >
            <Link
              to="/subscription"
              className={`
                rounded bg-transparent px-5 py-1 text-gray-800
                hover:underline
              `}
            >
              Pricing
            </Link>

            <AuthenticatedTemplate>
              <Link
                to="/app"
                className={`
                  rounded bg-transparent px-5 py-1 text-gray-800
                  hover:underline
                `}
              >
                Get Started
              </Link>
              <div className="px-4 font-medium text-yellow-500">
                Welcome, <span className="font-bold">{name}</span>
              </div>
            </AuthenticatedTemplate>

            {renderSignInSignOutButton()}
          </div>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className={`mt-4 flex flex-col space-y-2 lg:hidden`}>
            <Link
              to="/subscription"
              className={`
                block rounded bg-transparent px-5 py-2 text-center text-gray-800
                hover:underline
              `}
            >
              Subscription
            </Link>

            <AuthenticatedTemplate>
              <Link
                to="/app"
                className={`
                  block rounded bg-transparent px-5 py-1 text-gray-800
                  hover:underline
                `}
              >
                Get Started
              </Link>
              <div className="px-4 py-2 font-medium text-yellow-500">
                Welcome, <span className="font-bold">{name}</span>
              </div>
            </AuthenticatedTemplate>

            {renderSignInSignOutButton()}
          </div>
        )}
      </nav>

      <div className="w-full bg-gray-300"></div>
    </>
  );
};

export default NavBar;
