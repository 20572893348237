import axios from 'axios';
import { useState } from 'react';
import { getApiHost } from '../utils/getApiHost';
import { loginRequest } from '../authConfig';
import { useMsal } from '@azure/msal-react';
import { useErrorBoundary } from 'react-error-boundary';

const CustomerPortal = () => {
  // const [customerId, setCustomerId] = useState(null);
  const [error, setError] = useState(null);
  const { showBoundary } = useErrorBoundary();
  const { instance } = useMsal();

  const handleCustomerPortal = async () => {
    try {
      setError(null); // Clear any previous errors

      const authResult = await instance.acquireTokenSilent({
        ...loginRequest,
      });
      const apiUrl = getApiHost();

      const response = await axios.post(
        `${apiUrl}/user/customer-portal`,
        { redirectUrl: window.location.href },
        {
          headers: {
            Authorization: `Bearer ${authResult.idToken}`,
          },
        }
      );

      const data = await response.data;
      const portalUrl = data.portal_url;
      // Redirect to the Stripe Checkout page
      window.location.href = portalUrl;
      // setCustomerId(data.customer_id); // Update state with the customer ID
    } catch (err: any) {
      showBoundary(err);
    }
  };

  return (
    <div>
      <button
        onClick={handleCustomerPortal}
        className={`
          mt-6 rounded-lg border border-solid border-gray-700 bg-transparent
          px-8 py-3 text-2xl font-semibold text-gray-700
          hover:bg-yellow-100
          focus:outline-hidden
        `}
      >
        My Subscriptions
      </button>
      {error && <p className="mt-4 text-red-500">Error: {error}</p>}
    </div>
  );
};

export { CustomerPortal };
